import axios from 'axios';
import authHeader from './auth-header';

let API_URL

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  API_URL = "http://localhost:8080/api/test/"
} else if (process.env.NODE_ENV === 'test'){
  API_URL = "https://staging.api.segurosviagem.pt/api/test/"
}else {
  API_URL = "https://api.segurosviagem.pt/api/test/"
}

class UserService {
  getPublicContent() {
    return axios.get(API_URL + 'all');
  }

  getUserAccess() {
    return axios.get(API_URL + 'user', { headers: authHeader() });
  }

  getModeratorAccess() {
    return axios.get(API_URL + 'mod', { headers: authHeader() });
  }

  getAdminAccess() {
    return axios.get(API_URL + 'admin', { headers: authHeader() });
  }
}

export default new UserService();